@import 'variables';

.m-detail-chart {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border: 1px solid darkgrey;
    margin: 1rem 0;
    position: relative;
    overflow: hidden;

    @include minlg {
        flex-direction: row;
    }

    &__link {
        display: flex;
        justify-content: space-between;
        width: 100%;
        text-decoration: none;
        transition: opacity 0.2s 0.2s;
        color: inherit;

        @include maxmd {
            flex-direction: column;
        }

        &--lazy-preview {
            opacity: 0;
        }
    }

    &__lazy-loading-preview {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.85em;
    }

    &__detail-line-chart {
        max-height: 8rem;
    }

    &__detail {
        display: flex;
        flex-direction: row;
        align-items: center;

        @include maxmd {
            justify-content: center;
        }

        &__trend-arrow {
            height: 50px;
            width: 50px;
            border: 5px solid #393939;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 1rem;
            flex-shrink: 0;

            &__image {
                transform: translateX(1px) rotateZ(180deg);
            }
        }

        &__info {
            display: flex;
            align-items: flex-start;
            flex-direction: column;

            &__place {
                margin-bottom: 0;
            }

            &__time {
                margin-top: 0.2rem;
            }
        }
    }

    &__chart-wrapper {
        max-width: 350px;
        min-width: 350px;
        padding: 0.2rem 0.5rem;
        margin-left: 1rem;

        @include maxmd {
            max-width: unset;
            min-width: unset;
            margin-left: 0;
            height: 100%;
        }
    }
}
