@import 'variables';

.v-status-report {
    &__root {
        margin-bottom: 2rem;
    }

    &__riverarea,
    &__river {
        position: relative;
        margin-bottom: 2rem;
        margin-left: 1rem;
        padding-left: 1rem;
        border-left: 2px solid $color-button-hover;
    }

    &__info {
        padding: 1rem;
        background-color: $color-background-box;
    }

    &__notice {
        font-weight: bold;
    }
}
