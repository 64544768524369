@import 'variables';

.m-detailbox {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background: $color-background-primary;
    border-radius: 8px;
    box-shadow: -2px 2px 14px rgba(0, 0, 0, 0.24);
    overflow: hidden;

    &__accordion {
        &__heading {
            margin: 0;
        }
        &-wrapper {
            display: flex;
            flex-direction: column;
        }
    }

    &--open {
        .m-detailbox__body {
            overflow-x: hidden;
            overflow-y: auto;
            visibility: visible;
        }

        .m-detailbox__toggle-button {
            &__info-icon {
                display: none;
            }

            &__inner {
                display: block;
                transform: scale(1) translateX(0) rotateZ(45deg);
                &::before,
                &::after {
                    opacity: 1;
                }
            }
        }
    }

    &__head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem;
    }

    &__heading {
        margin: 0;
        white-space: nowrap;
    }

    &__toggle-button {
        flex-shrink: 0;
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 5px;
        background: $color-primary;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;

        &__inner {
            display: none;
            //transition: transform .4s;

            &::before,
            &::after {
                content: '';
                position: absolute;
                background: $color-white;
                left: 50%;
                top: 50%;
                opacity: 0;
                transform: translate(-50%, -50%);
                //transition: opacity .4s;
            }

            &::before {
                width: 15px;
                height: 3px;
            }

            &::after {
                width: 3px;
                height: 15px;
            }
        }
    }

    &__body {
        padding: 0 1rem 1rem;
        overflow: hidden;
        visibility: hidden;
    }

    &__message {
        margin-bottom: 1rem;
        margin-top: 1rem;
    }
}
