@import 'variables';

.m-content-box {
    display: flex;
    flex-direction: column;
    max-height: 0;
    background: $color-background-primary;
    border-top: solid 3px $color-border-primary;

    &--open {
        max-height: 100%;

        .m-content-box__inner {
            overflow-y: auto;
            visibility: visible;
        }

        .m-content-box__toggle-button {
            // This looks just nicer than the border overlapping button without rounded lower corners.
            top: -3px;

            .MuiSvgIcon-root {
                transform: rotate(180deg);
            }
        }

        .m-content-box__toggle-button {
            transform: translate(-50%, 0);
            border-radius: 0 0 5px 5px;
        }

        @include minlg {
            .m-content-box__toggle-button {
                // top: 0;
                transform: translate(-50%, -100%);
                border-radius: 5px 5px 0 0;
            }
        }
    }

    &__toggle-button {
        position: absolute;
        width: 5rem;
        height: 2.5rem;
        top: 0;
        left: 50%;
        transform: translate(-50%, -100%);
        border-radius: 5px 5px 0 0;
        background: $color-primary;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;

        .MuiSvgIcon-root {
            fill: $color-white;
            font-size: 2rem;
        }

        @include minmd {
            border-radius: 5px 5px 0 0;
            transform: translate(-50%, -100%);
        }
    }

    &__inner {
        overflow: hidden;
        z-index: 1;
        visibility: hidden;
    }
}
