@import 'variables';

.l-breadcrumb-bar {
    width: 100%;
    margin-bottom: 3rem;
    border-top: 5px solid $color-white;
    background: $color-primary;
    font-size: 1rem;

    &__inner {
        display: flex;
        padding: 1rem 0;
        color: $color-white;
    }

    a {
        color: inherit;
    }

    .path-divider {
        color: $color-white;
        vertical-align: middle;
    }

    .path-current {
        font-weight: bold;
    }

    .path-wrapper {
        max-width: 50%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
}
