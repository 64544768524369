@import 'variables';

.v-home {
    position: absolute;
    top: 3px;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;

    .e-map {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: auto;
    }

    &__content {
        // display: none;
        min-height: 200px;
        background: white;
    }

    &__body {
        background: $color-background-primary;

        > .container {
            padding-top: 2rem;
            padding-bottom: 2rem;

            @include maxsm {
                padding-top: 1rem;
                padding-bottom: 1rem;
            }
        }
    }
}
