@import 'variables';

.static-page {
    // a {
    //     color: $color-primary;
    // }

    // h1,
    // h2,
    // h3 {
    //     color: $color-primary;
    // }

    // h2,
    // h3 {
    //     border-bottom: 2px solid $color-button-hover;
    // }

    table {
        width: 100%;
        margin-right: 0;
        margin-bottom: 2rem;
    }

    thead {
        border-bottom: 2px solid $color-button-hover;
        // font-size: $typo-size-text;
    }

    .gruppe {
        background: $color-background-box;
        font-weight: bold;
        // font-size: $typo-size-small;
        padding: 6px 6px 6px 0.75rem;
    }

    th h4 {
        margin: 0;
        font-size: $typo-size-text;
        text-align: left;
    }

    td,
    th {
        padding: 6px 6px 6px 0.75rem;
        // padding: .2rem 0 .2rem .5rem;
        // font-size: $typo-size-text;
    }

    .infotext {
        font-size: $typo-size-small;
    }

    .subcolumns {
        display: flex;

        .c50l,
        .c50r {
            width: 50%;
        }

        .c66l,
        .c66r {
            width: 66.66%;
        }

        .c33l,
        .c33r {
            width: 33.33%;
        }
    }

    .img-wrap {
        display: inline-block;
        position: relative;
        vertical-align: top;
        line-height: 0;

        img {
            display: inline-block;
        }

        .e-help-icon {
            position: absolute;
            top: 0;
            right: 0;
            margin: 0;
            vertical-align: unset;
            z-index: 1;
        }
    }
}
