@import 'variables';

.e-preview-chart {
    display: flex;
    justify-content: center;
    height: 8rem;
    align-items: center;
    flex-direction: column;
    // padding-bottom: 18px;
    // position: relative;

    canvas {
        height: inherit;
    }
}
