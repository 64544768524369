@import 'variables';

/**
 * Basic styles
 */

html {
    font-size: $typo-size-base;
}

/**
 * Typography
 */

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-weight: bold;
    line-height: 1.2;
}

h1,
.h1 {
    // Margin minus line height offset
    margin: 0 0 (28px - 3);
    font-size: $typo-size-h1;
}

h2,
.h2 {
    // Margin minus line height offset
    margin: (24px - 3) 0;
    font-size: $typo-size-h2;
    color: $color-primary;
}

h3,
.h3 {
    // Margin minus line height offset
    margin: (20px - 2) 0;
    font-size: $typo-size-h3;
}

h4,
.h4 {
    // Margin minus line height offset
    margin: (16px - 2) 0;
    font-size: $typo-size-h4;
}

h5,
.h5 {
    margin: 0;
    font-size: $typo-size-h5;
    color: $color-primary;
}

p {
    margin: 0 0 1rem;
}

address {
    font-style: normal;
    margin: 1rem 0;
}
// p, a, li {
//     font-size: $typo-size-text;
// }

a {
    // color: inherit;
    color: $color-primary;

    &:focus-visible {
        outline: -webkit-focus-ring-color auto 1px !important;
    }
}

section {
    padding: 0;
}

img {
    max-width: 100%;
}

/**
 * Generic classes
 */
.small {
    font-size: $typo-size-small;
}

.nobr {
    word-break: keep-all;
    white-space: pre;
}

/**
 * Layout
 */
.l-app {
    font-family: $typo-text;
    font-size: 1rem;
    color: $color-typo-primary;
    background: $color-white;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
}

.l-content-box {
    margin-bottom: 1rem;
    padding: 2rem;
    background: $color-background-box;

    .button {
        background: $color-primary;
        color: $color-white;
        text-transform: none;
        font-size: $typo-size-text;

        &:hover {
            background-color: $color-button-hover;
        }
    }
}

// Windows scroll bar handling to prevent browser showing 2 scroll bars next to each other.
@keyframes delay-overflow {
    from {
        overflow: hidden;
    }
    to {
        overflow: hidden;
    }
}
