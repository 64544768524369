@import 'variables';

.v-warnings {
    &__section {
        position: relative;

        &:not(:first-of-type) {
            margin-top: 4rem;
        }
    }

    &__scroll-anchor {
        width: 1px;
        height: 1px;
        position: absolute;
        top: -6rem; // used to generate some offset for scrollintoview without the need of extra js

        @include minlg {
            top: -10rem;
        }
    }

    &__heading {
        margin-bottom: 0.25rem;
    }

    &__subheading {
        font-size: 0.875em;
        margin-bottom: 1.5rem;
    }

    &__description {
        margin: 1rem 0 2rem;
    }

    &__table-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &__table-row.top {
        border-bottom: 2px solid $color-button-hover;
    }

    &__table-head {
        background: $color-background-box;
    }

    &__table-cell {
        padding: 6px !important;
        text-align: center !important;

        &:first-of-type {
            text-align: left !important;
            padding-left: 0.75rem !important;
        }

        &--color {
            position: relative;
        }

        &--color svg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
    .MuiTableCell-root {
        font-size: $typo-size-text;
    }

    &__table-cell.heading,
    .heading-top {
        font-weight: bold;
        font-size: $typo-size-text;
    }
}
