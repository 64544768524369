@import 'variables';

.m-static-content {
    overflow: hidden;

    &__inner {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: -1rem;

        @include maxsm {
            margin: -0.5rem;
        }
    }

    &__tile {
        margin: 1rem;
        display: flex;
        flex-direction: column;
        background: white;
        width: calc(25% - 2rem);

        @include maxmd {
            width: calc(50% - 2rem);
        }

        @include maxsm {
            flex: 100%;
            margin: 0.5rem;
            // margin: 1rem 0 0 0;

            // &:first-of-type {
            //     margin-top: 0 !important;
            // }

            // &:last-of-type {
            //     margin-bottom: 1rem;
            // }
        }

        &__paragraph {
            margin: 1rem 0;
        }

        &__img {
            &.border {
                border: 2px solid #344c99;
            }

            img {
                width: 100%;
                max-height: 100%;
                margin: auto;
                display: block;
            }
        }

        &__headline {
            font-size: $typo-size-large;
            color: $color-primary;
            margin-bottom: 0;
            margin-top: 0;
        }

        &__link {
            display: block;
            color: $color-primary;
            width: 100%;
            margin-top: auto;
        }

        small {
            margin-top: 0;
            color: $color-typo-secondary;
        }

        &__text {
            background: white;
            padding: 1rem;
            height: 100%;
            display: flex;
            flex-direction: column;
        }
    }
}
