@import 'variables';

.l-app {
    min-height: 100vh;
    /* Corrected viewport height using css custom variables. */
    min-height: calc(var(--vh, 1vh) * 100);

    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .l-main {
        position: relative;
        flex-grow: 1;

        /* equal to header height */
        margin-top: 4.1rem;

        // @include maxsm {
        //     margin-top: 80px;
        // }

        @include minmd {
            margin-top: 4.1rem;
        }

        @include minlg {
            margin-top: 7rem;
        }

        //@include minxxl {
        //    padding-top: 116px;
        //}
    }
}
