@import 'variables';

.m-detail-measurementsites {
    &__table-head.MuiTableHead-root {
        background: $color-primary;
    }

    &__table-container {
        margin-bottom: 2rem;

        > .MuiTable-root ~ .m-detail-measurementsites__table-info-text {
            margin-top: 1rem;
        }

        > :last-child {
            margin-bottom: 0;
        }

        > .m-detail-measurementsites__table-info-text {
            font-size: $typo-size-small;
        }
    }

    .MuiTableRow-head {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        padding: 0.5rem 1rem;
    }

    &__table {
        margin-top: 2rem;
    }

    &__table-info-text {
        padding: 0 1rem;

        &__link {
            color: $color-primary;
        }
    }

    &__table-row {
        width: 100%;

        &__details {
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            width: 100%;
        }
    }

    &__prediction-lable {
        margin: 1rem 0;
        text-align: center;
    }

    .MuiTableCell-root {
        padding: 0.5rem 0.1rem;
        font-size: $typo-size-text;
    }

    &__tab-panel {
        @media (min-width: $sm) and (max-width: 500px) {
            margin-top: 4rem;
        }
    }

    .MuiTableCell-root.MuiTableCell-head {
        text-align: center;
        font-size: 1.25rem;
        font-weight: bold;
        color: $color-white;
        border: none;
    }

    /* Accordion styles. */
    .MuiAccordion-root {
        background: none;
        box-shadow: none;

        &.Mui-expanded {
            margin: 0;
        }
    }

    .MuiAccordionSummary {
        &-root {
            padding: 0;

            &.Mui-focusVisible {
                background: none;
            }
            &,
            &.Mui-expanded {
                min-height: 3rem;
            }
        }
        &-content {
            justify-content: space-between;

            &,
            &.Mui-expanded {
                margin: 0;
            }
        }
        &-expandIconWrapper {
            .MuiSvgIcon-root {
                font-size: 2.5rem;
                // fill: white;
            }
        }
        &-expandIcon {
            position: absolute;
            left: 0;
        }
    }

    .MuiAccordionDetails-root {
        padding: 0;
        border-radius: 0;

        .nav-item {
            margin: 0;
            padding: 0;
            font-size: 1.5rem;

            @include minmd {
                font-size: 1.875rem;
            }

            &:before {
                display: none;
            }
        }
    }

    .MuiAccordionDetails-root {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    &__table-left {
        width: 50%;
        padding-left: 1rem !important;
    }

    &__table-cell {
        min-width: 33.33%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 2rem !important;

        &:last-of-type {
            width: 25%;
        }

        &__head {
            color: $color-white;
            font-size: 1rem;
            font-weight: 600;
            width: 33.33%;
            text-align: center;
        }
    }

    .grey {
        background: $color-background-box;
        color: black;
        font-weight: bold;
    }

    &__table-button,
    &__chart-button {
        margin-left: 0;
    }

    &__button-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex: 1 0 auto;
        margin-left: 1rem;
    }

    &__table-button,
    &__chart-button {
        height: 3rem;
        width: 3rem;
        border: none;
        background: $color-primary;
        border-radius: 5px;
        color: $color-white;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
        cursor: pointer;

        @media (min-width: $sm) and (max-width: 500px) {
            width: 66.66%;
        }

        &:first-of-type {
            margin-left: 0;
        }

        &__list-icon.MuiSvgIcon-root {
            width: 2.2rem;
            height: 2.2rem;
        }

        &__refresh-icon.MuiSvgIcon-root,
        &__chart-icon.MuiSvgIcon-root {
            width: 2rem;
            height: 2rem;
        }
    }

    &__chart {
        display: block;
        margin-top: 1rem;
    }

    .hidden {
        display: none;
    }

    &__action-buttons {
        position: absolute;
        bottom: 0.3rem;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        z-index: 1;

        @media (min-width: $sm) and (max-width: 500px) {
            bottom: unset;
            top: 4rem;
            width: 100%;
        }

        .e-help-icon {
            margin-left: 1rem;
        }
    }

    &__tab-box {
        padding: 0 !important;
    }

    &__navigation.MuiBox-root {
        position: relative;
        border-bottom: 1px solid $color-button-hover;
    }

    &__tab-button.MuiTab-root {
        color: $color-primary;
        text-transform: none;
        font-size: 1rem;
        opacity: 1;

        &:hover {
            background: $color-button-hover;
            color: $color-white;
        }

        &.Mui-selected {
            background: $color-primary;
            color: $color-white;
            font-weight: 500;

            &:hover {
                background: $color-primary;
            }
        }
    }

    .MuiTabs-indicator {
        background-color: $color-primary;
    }

    @media (min-width: 600px) {
        &__tab-button.MuiTab-root {
            min-width: 100px;
        }
    }

    &__meta-data {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &__info {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            // justify-content: center;

            &__last-measurement {
                margin-bottom: 0.2rem;
            }

            &__legend {
                display: flex;

                &--text {
                    margin: 0 0.2rem 0 0;
                }

                &--url {
                    margin: 0 0 0 0.2rem;
                    color: $color-primary;
                }
            }
        }

        &__operator {
            &--name,
            &--url,
            &--heading {
                display: block;
                margin: 0;
                font-weight: bold;
                text-align: right;
            }
        }
    }
}
