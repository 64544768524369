@import 'variables';

.m-infobox {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background: $color-background-primary;
    border-radius: 8px;
    box-shadow: -2px 2px 14px rgba(0, 0, 0, 0.24);
    overflow: hidden;

    &--open {
        .m-infobox__body {
            overflow-x: hidden;
            overflow-y: auto;
            visibility: visible;
        }

        .m-infobox__toggle-button .MuiSvgIcon-root {
            transform: rotate(0);
        }
    }

    &__head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem;
    }

    &__heading {
        margin: 0;
    }

    &__toggle-button {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 5px;
        background: $color-primary;
        position: relative;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;

        .MuiSvgIcon-root {
            fill: $color-white;
            font-size: 2rem;
            transform: rotate(180deg);
        }
    }

    &__body {
        padding: 0 1rem 1rem;
        overflow: hidden;
        visibility: hidden;
    }

    &__checkbox-label-wrap {
        width: 55%;
    }

    .MuiCheckbox-root {
        padding: 0.357rem 0.714rem;
    }

    &__checkbox-label {
        .MuiTypography-body1 {
            font-size: $typo-size-small;
        }
    }

    /* Legends */
    &__legends-wrap {
        // padding-left: 11px;
        margin-top: 1rem;
        font-size: $typo-size-small;

        @include maxsm {
            font-size: 10px;
        }
    }

    &__legend-row,
    &__legend-row-gap {
        display: flex;
        margin-bottom: 0.25rem;
    }

    &__legend-row-gap {
        margin-top: 1.5rem;
    }

    &__legend-headings {
        display: flex;
        flex-direction: column;
        margin-bottom: 0.65rem;
        justify-content: center;
        align-items: center;
        width: 33.33%;
    }

    &__legend-heading {
        margin-bottom: 0;
        font-size: $typo-size-text;
        white-space: nowrap;
        text-align: center;

        @include maxlg {
            white-space: wrap;
        }

        @include maxsm {
            margin-top: 0.5rem;
        }
    }

    &__legend-color,
    &__legend-alert-class {
        flex-basis: 30%;
        text-align: center;
    }

    &__legend-label {
        flex-basis: 40%;
        text-align: center;
    }

    &__legend-color,
    &__legend-alert-class,
    &__legend-label {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__color-circle {
        display: inline-block;
        width: 13px;
        height: 13px;
        margin-top: 3px;
        background: $color-typo-tertiary;
        border: solid 1px $color-typo-tertiary;
        border-radius: 50%;

        &--white {
            background: $color-white;
        }
    }

    &__color-square {
        display: inline-block;
        width: 13px;
        height: 13px;
        margin-top: 3px;
        background: $color-typo-tertiary;
        border: solid 1px $color-typo-tertiary;

        &--white {
            background: $color-white;
        }
    }

    &__circle-prediction::before {
        content: 'V';
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-size: 9px;
        transform: scaleX(1.2) scaleY(0.9);
    }

    &__color-box {
        display: inline-block;
        width: 13px;
        height: 13px;
        margin-top: 3px;
        background: $color-typo-tertiary;
        border: solid 1px $color-typo-tertiary;

        &--white {
            background: $color-white;
        }
    }

    /* Search. */
    &__search-wrap {
        margin-top: 0;
    }

    .search {
        position: relative;
        margin-top: 1rem;

        &__input {
            display: block;
            background: $color-white;
            padding: 0.5rem;
            border: solid 1px $color-border-primary;
            min-height: 1.8rem;
            max-height: 2.8rem;
            overflow: hidden;
            width: 100%;
            border-radius: 4px;

            &:focus {
                background: $color-white;
                outline: -webkit-focus-ring-color auto 1px;
            }

            &::placeholder {
                font-size: $typo-size-small;
            }

            &__list-item {
                padding-left: 0.5rem;
                margin: 0.2rem 0;
            }
        }

        &__icon-wrap {
            position: absolute;
            top: 0;
            right: 3px;
            bottom: 0;
            display: flex;
            align-items: center;
            padding-right: 3px;
        }

        &__icon {
            width: 11px;
            height: auto;
        }
    }

    .MuiInput-underline::before {
        border-bottom: none;
    }

    .MuiInput-underline:hover::before {
        border-bottom: none !important;
    }

    .MuiInput-underline.Mui-focused::after {
        border-bottom: none;
    }

    .MuiAutocomplete-popper {
        width: 100% !important;
    }

    &__links-wrap {
        padding: 0.5rem;
    }

    &__link {
        display: inline-block;
        margin-top: 1rem;
        padding: 0 0.5rem;
        color: $color-primary;
        text-decoration: underline;
        cursor: pointer;
    }

    &__legend-prealert-overlay {
        transform-origin: center;
        scale: 2;
    }
}
