@import 'variables';

.m-alert-info {
    &__body {
        background: $color-white;
        padding: 1rem;
        flex-grow: 1;
    }

    &__heading {
        margin: 0;
        color: $color-typo-primary;
    }

    &__subheading {
        font-size: 0.875em;
        margin-bottom: 0;
    }

    &__box {
        display: flex;
        background: $color-background-box;
        position: relative;
        padding-left: 3rem;
    }

    &__color-column {
        max-width: 3rem;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
    }

    &__link {
        font-size: 1rem;
        float: right;
    }
}
