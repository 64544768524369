@import 'variables';

.e-legend {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0 -0.5rem 0.5rem;
    flex-wrap: wrap;

    &__legends-row {
        display: flex;
        align-items: center;
        margin: 0.5rem;
        cursor: pointer;

        &__active .e-legend__color-box {
            border-width: 0;
        }

        .MuiCheckbox-root {
            margin: -9px 0 -9px -9px;
        }
    }

    &__color-box {
        width: 13px;
        height: 13px;
        margin-right: 0.5rem;
        border: solid 2px $color-typo-tertiary;
    }

    &__legend-line {
        display: block;
        height: 3px;
        width: 1.7rem;
        margin-right: 0.5rem;
    }

    &__legend-line-wrapper {
        min-width: 1.5rem;
    }

    .marine-value {
        border: none;
        background-color: #ffffff;
        opacity: 1;
        background-image: linear-gradient(to right, #000000, #000000 5px, #ffffff 5px, #ffffff);
        background-size: 7px 100%;
        height: 4px;
    }
}
