@import 'variables';

.l-app--scroll {
    .l-header {
        box-shadow: 0 2px 14px rgba(0, 0, 0, 0.24);

        &__header-wrap {
            padding: 0.35rem 0;
        }
    }
}

.l-header {
    position: fixed;
    border-bottom: solid 4px $color-primary;
    top: 0;
    left: 0;
    right: 0;
    z-index: 20;
    transition: box-shadow 0.4s;

    &__header-wrap {
        padding: 1.25rem 0;
        background: $color-background-primary;
        transition:
            background 0.4s,
            padding 0.4s;

        @include maxmd {
            padding: 0.25rem 0;
        }
    }

    &__container {
        display: flex;
        align-items: center;
        @include maxsm {
            .l-header__heading {
                width: 100%;
                font-size: 2.8vw;
            }
        }
    }

    &__heading {
        font-size: $typo-size-h3;
        margin: 0;
        word-break: break-word;

        span {
            white-space: nowrap;
        }

        @include maxmd {
            text-align: center;
        }
        @include minmd {
            margin: 0 1rem;
        }
        @include minlg {
            margin: 0 2rem;
        }
        color: $color-typo-secondary;
        transition: color 0.4s;
    }

    &__logo-wrap {
        margin-left: auto;
        padding: 0.5rem;
        display: flex;
        align-items: center;

        img {
            height: 3.5rem;

            @include maxmd {
                height: 2.6rem;
            }
        }
    }

    &--menu-open {
        .l-header {
            box-shadow: 0 2px 14px rgba(0, 0, 0, 0.24);
        }

        .l-header__header-wrap {
            background: $color-primary;
        }

        .l-header__heading {
            color: $color-white;
        }
    }
}
