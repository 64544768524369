@import 'variables';

.v-rainfall-maps {
    .container {
        padding: 1rem 0 0;
    }

    &__tabpanel {
        padding: 0 !important;
    }

    &__navigation.MuiBox-root {
        position: relative;
        border-bottom: 1px solid $color-button-hover;
    }

    &__tab-button.MuiTab-root {
        color: $color-primary;
        text-transform: none;
        font-size: 1rem;
        opacity: 1;

        &:hover {
            background: $color-button-hover;
            color: $color-white;
        }

        &.Mui-selected {
            background: $color-primary;
            color: $color-white;
            font-weight: 500;

            &:hover {
                background: $color-primary;
            }
        }
    }

    .MuiTabs-indicator {
        background-color: $color-primary;
    }

    @media (min-width: 600px) {
        &__tab-button.MuiTab-root {
            min-width: 100px;
        }
    }
}
