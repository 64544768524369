@import 'variables';

.e-detail-chart {
    position: relative;

    &__detail-chart-wrapper {
        .e-help-icon {
            margin: 0;
            vertical-align: unset;
        }
    }

    &__legend {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;

        &__save-chart-link {
            color: $color-primary;
        }

        .e-help-icon {
            vertical-align: unset;
        }
    }

    &__loading-window {
        width: 100%;
        height: 100%;
        background: white;
        display: flex;
        justify-content: center;
        align-items: center;
        &__text {
            text-align: center;
            font-weight: bold;
            vertical-align: center;
        }
    }

    &__legend-item {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        // Make space for all 9 percentiles.
        min-height: (9 * 3px);
    }

    &__legend-lines {
        min-width: 1.5rem;
        margin-right: 0.5rem;

        span {
            display: block;
            height: 3px;
            width: 3rem;
        }
    }
}

.e-detail-chart__loading-window {
    @include maxsm {
        max-height: 522px;
        min-height: 389px;
    }

    @include onlymd {
        max-height: 420px;
        min-height: 389px;
    }

    @media (min-width: $lg) and (max-width: 1239px) {
        min-height: 413px;
        max-height: 567px;
    }

    @media (min-width: 1240px) and (max-width: $xl) {
        min-height: 493px;
        max-height: 600px;
    }

    @include onlyxl {
        min-height: 569px;
        max-height: 900px;
    }

    @include minxxl {
        min-height: 748px;
    }
}
