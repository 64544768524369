@import 'variables';

.e-message {
    display: flex;
    min-height: 5rem;
    background: $color-white;

    &--type-info {
        background: #ededed;

        .e-message__icon-wrap {
            background: #8d8d8d;
        }
    }

    &--type-error {
        background: #ecb4ae;

        .e-message__icon-wrap {
            background: #e42914;
        }
    }

    &--type-warning {
        background: #ffebb3;

        .e-message__icon-wrap {
            background: #f6d036;
        }
    }

    &__icon-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 3rem;
        padding: 1rem;
        background: $color-background-primary;
    }

    &__content-wrap {
        position: relative;
        width: 100%;
        padding: 1rem;

        .e-help-icon {
            float: right;
        }
    }

    &__content-inner {
        font-size: $typo-size-small;
    }

    &__heading {
        margin-top: 0;
        font-size: $typo-size-small;
        font-weight: 700;
    }

    &__content {
        margin: 0;
        font-size: 0.75rem;
    }
}
