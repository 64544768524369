// Fonts

$typo-text: 'Arial Regular', sans-serif;

//Font-Sizes
$typo-size-base: 14px;

$typo-size-h1: 28px;
$typo-size-h2: 24px;
$typo-size-h3: 20px;
$typo-size-h4: 16px;
$typo-size-h5: $typo-size-base;

$typo-size-large: 1.214rem;
$typo-size-text: 1rem;
$typo-size-small: 0.875rem;

// Colors

$color-typo-primary: #333;
$color-typo-secondary: #4a4a48;
$color-typo-tertiary: #8e8e8e;
$color-background-primary: #ededed;
$color-border-primary: #c6c6c6;
$color-primary: #881c34;
$color-background-box: #ededed;
$color-white: #fff;
$color-button-hover: #8d8d8d;

// Breakpoints and media mixins

$sm: 320px;
$md: 768px;
$lg: 1024px;
$xl: 1440px;
$xxl: 1620px;

@mixin minsm {
    @media (min-width: $sm) {
        @content;
    }
}

@mixin maxsm {
    @media (max-width: ($md - 1)) {
        @content;
    }
}

@mixin minmd {
    @media (min-width: $md) {
        @content;
    }
}

@mixin onlymd {
    @media (min-width: $md) and (max-width: ($lg - 1)) {
        @content;
    }
}

@mixin maxmd {
    @media (max-width: ($lg - 1)) {
        @content;
    }
}

@mixin minlg {
    @media (min-width: $lg) {
        @content;
    }
}

@mixin onlylg {
    @media (min-width: $lg) and (max-width: ($xl - 1)) {
        @content;
    }
}

@mixin maxlg {
    @media (max-width: ($xl - 1)) {
        @content;
    }
}

@mixin minxl {
    @media (min-width: $xl) {
        @content;
    }
}

@mixin onlyxl {
    @media (min-width: $xl) and (max-width: ($xxl - 1)) {
        @content;
    }
}

@mixin maxxl {
    @media (max-width: ($xxl - 1)) {
        @content;
    }
}

@mixin minxxl {
    @media (min-width: $xxl) {
        @content;
    }
}
