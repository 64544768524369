@import 'variables';

.e-peaklevels {
    &__table-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &__table-row.top {
        border-bottom: 2px solid $color-button-hover;
    }

    &__table-head {
        background: $color-background-box;
    }

    &__table-cell {
        padding: 6px !important;
        width: 33.33%;
        text-align: center !important;

        &:first-of-type {
            text-align: left !important;
            padding-left: 0.75rem !important;
        }

        a {
            color: $color-primary;
        }
    }
    .MuiTableCell-root {
        font-size: $typo-size-text;
    }

    &__table-cell.heading,
    .heading-top {
        font-weight: bold;
        font-size: $typo-size-text;
    }
}
