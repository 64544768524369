@import 'variables';

.page-not-exist {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    margin-top: 5rem;
    text-align: center;

    a {
        font-size: 1rem;
    }
}
