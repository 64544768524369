@import 'variables';

.e-map__neighbour-countries {
    &__tooltip {
        margin: 0;
        padding: 0;
        background: none;
        border: none;
        border-radius: none;
        box-shadow: none;
        z-index: 800;

        a {
            color: $color-primary;
        }
    }
}
