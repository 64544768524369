@import 'variables';

// Because we are not using any leaflet assets, we can safely include its styles without messing around with asset paths.
@import 'leaflet/dist/leaflet.css';

.e-map {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    opacity: 1;
    transition: opacity 0.4s 0.15s ease-in;

    &--loading {
        opacity: 0;
    }

    &__map {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 0;
    }

    &__overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        flex-direction: row;
        // flex-wrap: wrap;
        align-items: flex-start;
        justify-content: space-between;
        padding-top: 1rem;
        padding-bottom: 1rem;

        @include maxsm {
            padding-left: 7px;
            padding-right: 7px;
            align-items: flex-end;
        }

        .m-infobox {
            flex-shrink: 0;
            width: 33%;
            max-height: 4.5rem;
            z-index: 1;
            transition:
                width 0.15s 0.15s linear,
                margin-right 0.15s 0.15s linear,
                max-height 0.15s linear,
                z-index 0s 0.3s linear;

            &--open {
                max-height: 100%;
                overflow: hidden;
                z-index: 2;
                transition:
                    width 0.15s linear,
                    margin-right 0.15s linear,
                    max-height 0.15s 0.15s linear,
                    z-index 0s linear;

                .m-infobox__body {
                    animation: delay-overflow 0.3s linear;
                }

                .m-infobox__toggle-button .MuiSvgIcon-root {
                    transition: transform 0.15s 0.15s linear;
                }
            }

            &__toggle-button .MuiSvgIcon-root {
                transition: transform 0.15s linear;
            }

            @include maxsm {
                width: 4.5rem;
                max-width: none;

                &__heading {
                    width: 0;
                    transition: width 0s 0.15s linear;
                    overflow: hidden;
                }

                &__toggle-button .MuiSvgIcon-root {
                    transform: rotate(0);
                }

                &--open {
                    width: 100%;
                    margin-right: -4.5rem;

                    .m-infobox__heading {
                        width: auto;
                    }

                    .m-infobox__toggle-button .MuiSvgIcon-root {
                        transform: rotate(180deg);
                    }
                }
            }

            // In mobile landscape view restrict box width.
            @media (max-width: ($md - 1)) and (min-aspect-ratio: 1/1) {
                &--open {
                    width: 50%;
                }
            }
        }

        .m-detailbox {
            flex-shrink: 0;
            width: 33%;
            max-width: 800px;
            max-height: 4.5rem;
            z-index: 1;
            transition:
                width 0.15s 0.15s linear,
                margin-left 0.15s 0.15s linear,
                max-height 0.15s linear,
                z-index 0s 0.3s linear;

            &--open {
                width: 60%;
                max-height: 100%;
                z-index: 2;
                transition:
                    width 0.15s linear,
                    margin-left 0.15s linear,
                    max-height 0.15s 0.15s linear,
                    z-index 0s linear;

                // .m-detailbox__body {
                //     animation: delay-overflow .3s linear;
                // }

                @media (min-height: 900px) {
                    max-height: 760px;
                }
            }

            @include maxsm {
                width: 4.5rem;
                max-width: none;

                &__heading {
                    width: 0;
                    transition: width 0s 0.15s linear;
                    overflow: hidden;
                }

                &--open {
                    width: 100%;
                    margin-left: -4.5rem;

                    .m-detailbox__heading {
                        width: auto;
                    }
                }
            }

            // In mobile landscape view restrict box width.
            @media (max-width: ($md - 1)) and (min-aspect-ratio: 1/1) {
                &--open {
                    width: 60%;
                }
            }
        }
    }

    .m-content-box {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 4;
        transition: max-height 0.3s linear;

        &--open {
            @include minlg {
                max-height: calc(100% - 6.5rem);
            }

            .m-content-box__inner {
                animation: delay-overflow 0.3s linear;
            }

            @include maxsm {
                max-height: 100%;
            }
        }

        &__toggle-button {
            transition:
                // top .1s linear,
                border-radius 0.3s linear,
                transform 0.1s linear;

            /* TODO Refactor animations to prevent flickering on slower devices
            .MuiSvgIcon-root {
                // transition: transform .3s linear;
            }
            */
        }
    }

    .leaflet {
        &-container {
            background: none !important;
        }

        &-control-attribution,
        &-control {
            display: none;
        }

        &-control-container {
            max-width: 1440px;
            margin: 0 auto;
            position: relative;
        }

        &-pane.leaflet-marker-pane,
        &-marker-shadow {
            display: none;
        }
    }

    &__hatches-prerender {
        position: absolute;
        width: 0;
        height: 0;
        overflow: hidden;
    }

    &__tooltip {
        // This is necessary, otherwise markers would be shown above tooltips.
        z-index: 400;
        // While tooltips are repositioned during open they may flicker. Transitioning the open state effectivly hides this effect.
        transition: opacity 0.15s linear;

        &__station-name {
            text-align: center;
            font-size: $typo-size-small;
        }

        &__label-details {
            margin: 0;

            &__waterlevel,
            &__prediction {
                font-size: 0.8rem;
                margin: 0;
            }
        }

        &__link-wrapper {
            display: flex;
            justify-content: center;
            margin-top: 0.5rem;

            &__link {
                color: $color-primary;
            }
        }
    }

    &__mobile-preview {
        position: absolute;
        display: none;
        top: auto;
        right: 1rem;
        bottom: 1.75rem;
        left: 1rem;
        transform: translateY(-50%);
        z-index: 3;

        &--touch-device {
            display: block;
        }

        .inner {
            position: relative;
            margin: auto;
            padding: 10px;
            background: #fff;
            box-shadow: -2px 2px 14px rgba(0, 0, 0, 0.24);
            border-radius: 3px;
            max-width: 480px;
        }

        .head {
            display: flex;
            height: 12px;
        }

        .toggle-button {
            display: flex;
            flex-shrink: 0;
            justify-content: center;
            align-items: center;
            right: 1rem;
            top: 1rem;
            width: 30px;
            height: 30px;
            margin-left: auto;
            border-radius: 5px;
            background: $color-primary;
            cursor: pointer;

            &__inner {
                transform: rotate(45deg);

                &::before,
                &::after {
                    content: '';
                    position: absolute;
                    background: $color-white;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }

                &::before {
                    width: 15px;
                    height: 3px;
                }

                &::after {
                    width: 3px;
                    height: 15px;
                }
            }
        }

        .e-preview-chart {
            height: 12rem;

            @include minmd {
                height: 14rem;
            }
        }
    }
}
