@import 'variables';

.l-header--menu-open .m-main-menu {
    margin-top: 2px;
}

.m-main-menu {
    $open-duration: 0.4s;

    max-height: 0;
    background: rgba($color-primary, 0.92);
    transition:
        max-height $open-duration,
        margin-top $open-duration,
        visibility $open-duration;
    overflow: hidden;
    visibility: hidden;

    a {
        color: inherit;
    }

    h2 {
        color: $color-white;
        text-transform: uppercase;
    }

    small {
        display: block;
        margin-bottom: 1rem;
    }

    &--open {
        // Windows scroll bar handling to prevent browser showing 2 scroll bars next to each other.
        overflow-y: auto;
        animation: delay-overflow $open-duration linear;
        visibility: visible;

        @include maxmd {
            max-height: calc(100vh - 4.5rem);
        }

        @include minlg {
            max-height: calc(100vh - 7rem);
        }
    }

    &__main-area {
        display: flex;
        margin-top: 1rem;
        overflow-x: hidden;

        @include minmd {
            margin-top: 2rem;
        }

        @include maxsm {
            flex-direction: column;
        }
    }

    &__nav-wrap {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 55%;
        margin-right: 1rem;
        background: rgba($color-white, 0.05);

        @include maxsm {
            width: 100%;
        }
    }

    &__sidebar-wrap {
        width: 45%;
        margin-left: 1rem;
        display: flex;
        flex-direction: column;

        @include maxsm {
            width: 100%;
            margin-left: 0;
            margin-top: 1rem;
        }

        .sidebar-area {
            background: rgba($color-white, 0.05);
            padding: 1rem;
            margin-bottom: 1rem;

            @include minmd {
                padding: 1.5rem;
            }

            @include minlg {
                padding: 2rem;
            }

            color: $color-white;

            h2 {
                margin-top: 0;
            }

            &.sidebar-link-area:last-of-type {
                margin-bottom: 0;
            }

            &__links-wrap {
                margin: 0 -1rem;
                display: flex;
                align-items: flex-start;
                flex-wrap: wrap;

                .sidebar-link {
                    font-size: 1.3rem;
                    padding: 0.5rem 1rem;
                    white-space: nowrap;
                    font-weight: bold;

                    @include minmd {
                        font-size: 1.5rem;
                    }
                }
            }
        }

        .sidebar-link-area {
            text-decoration: none;
            transition: background 0.4s;

            &:hover {
                background: rgba($color-white, 0.65);

                .nav-link,
                h2,
                p,
                * {
                    color: $color-primary;
                }
            }

            &__read-more {
                display: block;
                margin-top: 1rem;
                font-weight: bold;
                text-decoration: underline;
            }
        }
    }

    &__bottom-area {
        padding: 1rem;

        .footer-nav {
            display: flex;
            justify-content: space-between;
            padding: 0 1rem;

            @include maxsm {
                flex-wrap: wrap;
                justify-content: center;
            }

            &__link {
                padding: 1rem;
                color: $color-white;
            }
        }
    }

    &__navigation {
        margin: 1rem 0;

        .nav-item {
            position: relative;
            padding: 0 1rem 0 0;
            font-size: 1.875rem;

            @include minmd {
                padding: 0 2rem;
                font-size: 2rem;
            }

            &:before {
                content: '';
                display: block;
                position: absolute;
                width: 4px;
                height: auto;
                top: 0;
                right: auto;
                bottom: 0;
                left: 0;
                z-index: 2;
                background-color: $color-white;
                opacity: 0;
                transition: opacity linear 200ms;
            }

            &:hover:before {
                opacity: 1;
            }
        }

        .nav-link {
            display: inline-block;
            padding: 0.5rem 1rem;
            font-size: inherit;
            font-weight: bold;
            text-decoration: underline;
            color: $color-white;
            transition:
                transform 0.4s,
                color 0.4s;

            @include minmd {
                font-size: 1.5rem;
                padding: 1rem;
            }

            &:hover {
                transform: scale(1.025);
            }
        }

        .MuiAccordion-root {
            background: none;
            box-shadow: none;

            &.Mui-expanded {
                margin: 0;
            }
        }

        .MuiAccordionSummary {
            &-root {
                padding: 0;

                &,
                &.Mui-expanded {
                    min-height: auto;
                }
                &.Mui-focusVisible {
                    background: none;
                    outline: -webkit-focus-ring-color auto 1px;
                }
            }
            &-content {
                &,
                &.Mui-expanded {
                    margin: 0;
                }
            }
            &-expandIconWrapper {
                .MuiSvgIcon-root {
                    font-size: 2.5rem;
                    fill: white;
                }
            }
        }
        .MuiAccordionDetails-root {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            // padding: 0;
            padding: 0 0 0.3rem;
            border-radius: 0;

            @include minmd {
                padding: 0 0 0 2rem;
            }

            .nav-item {
                margin: 0;
                padding: 0;
                font-size: 1.3rem;

                @include minmd {
                    font-size: 1.875rem;
                }

                &:before {
                    display: none;
                }
            }
        }
    }

    &__tile {
        &__headline {
            margin-bottom: 0;
            margin-top: 0;
        }
    }
}
