@import 'variables';

.e-status-report {
    display: flex;
    flex-direction: row;
    background: $color-white;
    margin-bottom: 1rem;

    &.detail {
        background: $color-background-primary;
        margin-bottom: 2rem;
    }

    &__link {
        color: $color-primary;
        float: right;
    }

    &__icon {
        flex: 1 0 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        background: $color-button-hover;

        &.is-warning {
            background: #e42914;
        }
    }

    &__items {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    &__body {
        flex: 1 1 100%;
        padding: 1rem;
        overflow: hidden;

        .h5 {
            color: $color-typo-primary;
            position: relative;

            .e-help-icon {
                float: right;
            }
        }

        &:after {
            clear: both;
        }
    }

    &__subheadline {
        margin-bottom: 0.5rem;
        margin-top: 0;
        font-size: $typo-size-small;
    }

    &__content {
        font-size: $typo-size-small;
    }
}
