@import 'variables';

.v-detail-measurement {
    .content-box {
        margin-bottom: 1rem;

        &__inner {
            background: $color-background-box;
            margin-bottom: 1rem;
            padding: 2rem;
        }

        .e-message {
            margin-bottom: 2rem;
        }
    }
}

// TODO Move this into seperate component!
.loading-screen {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #00000015;
    display: flex;
    justify-content: center;
    align-items: center;
}
