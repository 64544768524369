@import 'variables';

.e-accordeon {
    position: relative;

    &__heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        @media (max-width: 369px) {
            margin-bottom: 15px;
        }

        & > h1,
        & > h2,
        & > h3,
        & > h4,
        & > h5,
        & > h6,
        & > p {
            margin: 0;
        }
        & > img {
            transition: transform 0.3s;
        }
    }

    &__heading-inner {
        @media (min-width: 370px) {
            padding-right: 7rem;
        }
    }

    &__content-wrapper {
        display: grid;
        grid-template-rows: 0fr;
        transition: grid-template-rows 0.3s;
    }

    &__content {
        overflow: hidden;
    }

    &__content-inner {
        padding-top: 1rem;
    }

    &--open &__content-wrapper {
        grid-template-rows: 1fr;
    }

    &--open &__heading > img {
        transform: scaleY(-1);
    }

    .e-status-report,
    .m-alert-info {
        &__link {
            position: absolute;
            right: 2.5rem;
            bottom: calc(50% - 0.7rem);
        }

        &__link {
            @media (max-width: 369px) {
                right: 0;
                bottom: 0;
            }
        }
    }

    &--open {
        .e-status-report,
        .m-alert-info {
            &__link {
                position: static;
            }
        }
    }
}
