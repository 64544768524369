@import 'variables';

.e-map {
    .polygon {
        // border: 3px solid red;

        cursor: grab;

        &--has-data {
            cursor: pointer;
        }
    }

    .polygon--shadow {
        -webkit-filter: drop-shadow(0 0 12px rgba(0, 0, 0, 0.7));
        filter: drop-shadow(0 0 12px rgba(0, 0, 0, 0.7));
    }

    .polygon--shadow-light {
        -webkit-filter: drop-shadow(0 0 8px rgba(0, 0, 0, 0.11));
        filter: drop-shadow(0 0 8px rgba(0, 0, 0, 0.11));
    }
}
