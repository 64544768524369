@import 'variables';

.v-level-list {
    &__status {
        border: 1px solid black;
        height: 1rem;
        width: 1rem;
    }

    &__type-default {
        border-radius: 50%;
    }

    .MuiDataGrid-columnHeader {
        background: none;
        font-weight: bold;
        border-top: none;
        border-right: none;
        border-left: none;
        border-bottom: 3px solid $color-button-hover;

        &:not(&--sorted):hover .MuiDataGrid-sortIcon {
            opacity: 0.7 !important;
        }

        .MuiDataGrid-columnHeaderTitle {
            font-weight: bold;
        }

        .MuiDataGrid-iconSeparator {
            display: none;
        }
    }

    .MuiDataGrid-sortIcon {
        fill: black;
    }

    .MuiDataGrid-root {
        font-size: $typo-size-text;
        border: none;

        .MuiDataGrid-columnHeader,
        .MuiDataGrid-cell {
            &:first-child {
                padding-left: 0;
            }
            &:last-child {
                padding-right: 0;
            }

            &:focus,
            &:focus-within {
                outline: none;
            }
        }
    }

    .MuiDataGrid-footerContainer {
        border-top: none;
    }

    .MuiDataGrid-virtualScroller {
        border-bottom: 1px solid rgba(224, 224, 224, 1);
    }

    .MuiTablePagination-displayedRows {
        margin: 0;
    }
}
