@import 'variables';

.e-hamburger {
    width: 40px;
    height: 44px;
    background: $color-white;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: box-shadow 0.4s;

    &:hover {
        box-shadow: 0 3px 12px rgba(0, 0, 0, 0.12);
    }

    &__inner {
        position: relative;
        height: 11px;
        width: 15px;
        transition: transform 0.4s;
    }

    &__lines {
        position: absolute;
        left: 0;
        width: 100%;
        height: 3px;
        background: $color-primary;

        &.top {
            top: 0;
            transition: transform 0.4s;
        }

        &.middle {
            top: 5px;
            transition:
                left 0.4s,
                width 0.4s;
        }

        &.bottom {
            top: 10px;
            transition: transform 0.4s;
        }
    }

    &--open {
        &:hover {
            box-shadow: 0 3px 12px rgba(255, 255, 255, 0.4);
        }

        .e-hamburger__inner {
            transform: rotateZ(-90deg);
        }

        .e-hamburger__lines {
            &.top {
                transform: translateY(4px) rotateZ(-45deg);
            }

            &.middle {
                width: 2px;
                left: 50%;
            }

            &.bottom {
                transform: translateY(-6px) rotateZ(45deg);
            }
        }
    }
}
