@import 'variables';

.v-precipitation-index-map {
    .container {
        padding: 1rem 0 0;
    }

    .map-container {
        max-width: 680px;
    }
}
