@import 'variables';

.l-footer {
    margin-top: 4rem;

    &__top {
        margin-bottom: 2rem;
        display: flex;
        justify-content: center;
    }

    &__back-to-top {
        display: flex;
        justify-content: center;
        align-items: center;

        .button {
            width: 40px;
            height: 44px;
            background: $color-typo-tertiary;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            margin-right: 1rem;
            transition: box-shadow 0.4s;

            &:hover {
                box-shadow: 0 3px 12px rgba(0, 0, 0, 0.12);
            }
        }
    }

    &__main {
        padding: 2rem 0;
        border-top: solid 3px $color-border-primary;
    }

    &__subheading {
        color: $color-typo-tertiary;
        position: relative;
        padding-top: 0.7rem;
        text-transform: uppercase;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 20%;
            max-width: 68px;
            height: 2px;
            background: $color-typo-tertiary;
        }
    }

    &__nav {
        margin: 0 -1rem;
        display: flex;
        @include maxsm {
            flex-wrap: wrap;
        }
    }

    &__link {
        color: $color-typo-secondary;
        padding: 0.5rem 1rem;
        min-width: 18%;
    }
}
