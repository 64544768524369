@import 'variables';

.v-water-trend {
    .MuiDataGrid-columnHeader {
        border-bottom: 2px solid $color-button-hover !important;
    }
}

.v-water-trend-custom-header {
    @media (max-width: 600px) {
        white-space: pre-line;
        line-height: 1.2; /* Adjust as needed */
        padding-top: 4px; /* Adjust as needed */
        padding-bottom: 4px; /* Adjust as needed */
    }
}
