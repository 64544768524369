@import 'variables';

.m-detail-menu {
    .h4 {
        margin-top: 2rem;
        word-break: break-word;

        span {
            white-space: nowrap;
        }
    }

    &__select {
        margin-bottom: 0.5rem;
        background: $color-white;
        border: 1px solid $color-button-hover;
        border-radius: 4px;

        .MuiNativeSelect-select {
            padding-left: 0.5rem;

            &:focus {
                background: $color-white;
                outline: -webkit-focus-ring-color auto 1px;
            }
        }
    }

    .MuiInput-underline {
        &::before,
        &::after {
            display: none;
        }
    }

    .MuiInput-underline:hover:not(.Mui-disabled):before {
        border-bottom: none;
    }

    &__submit-wrapper {
        margin-top: 0.5rem;
    }
}
