.menu-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #000;
    opacity: 0;
    z-index: 19;
    visibility: hidden;
    transition:
        opacity 0.4s 0s,
        visibility 0s 0.4s;

    &--open {
        opacity: 0.4;
        visibility: visible;
        transition:
            opacity 0.4s 0s,
            visibility 0s;
    }
}
